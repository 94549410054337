import React, { useEffect, useState } from 'react'

import NavBar from '../components/navbar/NavBar'
import StarParalax from '../components/starparalax/StarParalax'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import SearchBar from '../components/searchbar/SearchBar'
import ArtistAvatar from '../components/artistavatar/ArtistAvatar'

import Slider from "react-slick";

import ExclusiveDrops from '../components/exclusivedrops/ExclusiveDrops'
import HotAuction from '../components/hotauction/HotAuction'
import FilterButton from '../components/filterbutton/FilterButton'
import ExploreImage from '../components/exploreimage/ExploreImage'
import BuyImage from '../assets/images/buy.png'
import MainLogo from '../assets/images/main-logo.png'

const Home = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x241b8e70e25495bb347b3e267ad2e27dcc3b2506')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <>
            <div className="landing-page-main">
                {/* <NavBar /> */}
                <div className="main-hero text">
                    <div className="container">
                        <h2>LAUNCHING</h2>
                        <h3>JANUARY 22, 2022</h3>
                        <h4>TONIGHT USA CENTRAL TIME</h4>
                        <a href="https://t.me/pizzabucks">t.me/pizzabucks</a>
                    </div>
                </div>

                {/* <Footer />
                <ScrollTop /> */}
            </div>

        </>
    )
}

export default Home
